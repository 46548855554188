import { Workflow, Block, BlockType } from './../../api/interfaces';
/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { INITIAL_STATE } from './initialState';
import { createReducer } from 'reduxsauce';
import { WorkflowTypes } from './actions';

type State = typeof INITIAL_STATE;

export const loadWorkflowRequest = (state: State, {wid}: any): State => {
  return {
    ...state,
    // data: false,
    selectedWorkflowId: wid,
    loading: true,
    errors: false,
  }
}

export const loadWorkflowSuccess = (state: State, { data }: any): State => {
  return {
    ...state,
    data: {
      ...state.data as any,
      [data.wid]: {
        ...data,
        edited: false,
      },
    } as any,
    loading: false,
  }
}

export const loadWorkflowFailure = (state: State, { errors }: any): State => ({
  ...state,
  errors,
  loading: false,
})

export const selectBlock = (state: State, { id }: any): State => {
  return {
    ...state,
    selectedBlockId: id
  };
}

export const deleteLocalWorkflowBlock = (state: State): State => {
  const workflow = (state.data as any)[state.selectedWorkflowId as any];
  console.log('~', workflow);
  return {
    ...state,
    ...updateLocalWorkflow(state, {
      data: {
        ...workflow,
        blocks: workflow.blocks.filter((b: any) => b.block_id != state.selectedBlockId),
      }
    }),
    selectedBlockId: false,
  }
}

export const createBlock = (state: State, {x_pos, y_pos, block_id, block_type = ''}: {x_pos: string, y_pos: string, block_id: string, block_type?: BlockType}): State => {
  const blocks = [...((state.data as any)[state.selectedWorkflowId as any] as unknown as Workflow).blocks];

  let description = '';
  let exits: { exit_dir: string }[] = [
    {
      exit_dir: '0',
    },
    {
      exit_dir: '0',
    },
  ];
  let exec = '';

  if (block_type === 'SW') {
    exits = [
      {
        exit_dir: '0',
      },
    ];
    description = '';
  } else if (block_type === 'RT') {
    exits = [];
    description = 'RETURN';
    exec = 'wofoEnv->return();';
  } else if (block_type === 'NT') {
    description = '';
    exits = [];
  }

  blocks.push({
    block_id,
    exec,
    description,
    type: block_type,
    x_pos,
    y_pos,
    last_mod: (new Date()).toISOString(),
    elements: [],
    exits,
  })

  return {
    ...state,
    data: {
      ...(state.data as any),
      [state.selectedWorkflowId as any]: {
        ...((state.data as any)[state.selectedWorkflowId as any]),
        edited: true,
        blocks
      }
    },
  };
}

export const duplicatesBlock = (state: State, {data: {block, block_id, elem_ids}}:  {data: {block: Block, block_id: string, elem_ids: string}}): State => {
  const blocks = [...((state.data as any)[state.selectedWorkflowId as any] as unknown as Workflow).blocks];
  blocks.push({
    ...block,
    block_id,
    x_pos: '' + (Number(block.x_pos) + 50),
    y_pos: '' + (Number(block.y_pos) + 50),
    elements: block.elements.map((el, idx) => ({...el, elem_id: elem_ids[idx]})),
    exits: block.exits.map(() => ({exit_dir: '0'})),
  })

  return {
    ...state,
    data: {
      ...(state.data as any),
      [state.selectedWorkflowId as any]: {
        ...((state.data as any)[state.selectedWorkflowId as any]),
        edited: true,
        blocks
      }
    },
  };
}

export const moveBlock = (state: State, { data: {block_id, x_pos, y_pos} }: any): State => {
  const blocks = ((state.data as any)[state.selectedWorkflowId as any] as unknown as Workflow).blocks.map(block => {
    if (block_id == block.block_id) {
      const res = {...block, x_pos, y_pos};
      return res;
    } else {
      return block;
    }
  });

  return {
    ...state,
    data: {
      ...(state.data as any),
      [state.selectedWorkflowId as any]: {
        ...((state.data as any)[state.selectedWorkflowId as any]),
        edited: true,
        blocks
      }
    },
    // debug: {
    //   blocks
    // } as any,
  }
}

export const addConnection = (state: State, { data: {source, target, index} }: any): State => {
  const blocks = ((state.data as any)[state.selectedWorkflowId as any] as unknown as Workflow).blocks.map(block => {
    if (source === block.block_id) {
      // const exits = block.exits.map(exit => exit.exit_dir === target ? { exit_dir: '0' } : exit);
      const exits = block.exits.map((exit, idx) => idx === index ? { exit_dir: target } : exit);
      const res = {...block, exits};
      return res;
    } else {
      return block;
    }
  });

  return {
    ...state,
    data: {
      ...(state.data as any),
      [state.selectedWorkflowId as any]: {
        ...((state.data as any)[state.selectedWorkflowId as any]),
        edited: true,
        blocks
      }
    },
  };
}

export const removeConnection = (state: State, { data: {source, target, index} }: any): State => {
  const blocks = ((state.data as any)[state.selectedWorkflowId as any] as unknown as Workflow).blocks.map(block => {
    if (source === block.block_id) {
      const exits = block.exits.map((exit, idx) => (exit.exit_dir === target && idx === index) ? { exit_dir: '0' } : exit);
      const res = {...block, exits};
      return res;
    } else {
      return block;
    }
  });

  return {
    ...state,
    data: {
      ...(state.data as any),
      [state.selectedWorkflowId as any]: {
        ...((state.data as any)[state.selectedWorkflowId as any]),
        edited: true,
        blocks
      }
    },
  };
}

export const updateLocalWorkflow = (state: State, { data }: any): State => {
  return {
    ...state,
    data: {
      ...state.data as any,
      [data.wid]: {
        ...data,
        edited: true,
      },
    },
    // data: {
    //   ...state.data as any,
    //   [state.selectedWorkflowId as any]: {
    //     ...(state.data as any)[state.selectedBlockId as any],
    //     ...data,
    //   },
    // },
    // loading: true,
    // errors: false,
  }
}

export const updateWorkflowRequest = (state: State, { id }: any): State => ({
  ...state,
  loading: true,
  errors: false,
})

export const updateWorkflowSuccess = (state: State): State => ({
  // TODO: mettere edited a [data.wid] false
  ...state,
  loading: false,
})

export const updateWorkflowFailure = (state: State, { errors }: any): State => ({
  ...state,
  errors,
  loading: false,
})

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [WorkflowTypes.LOAD_WORKFLOW_REQUEST]: loadWorkflowRequest,
  [WorkflowTypes.LOAD_WORKFLOW_SUCCESS]: loadWorkflowSuccess,
  [WorkflowTypes.LOAD_WORKFLOW_FAILURE]: loadWorkflowFailure,

  [WorkflowTypes.SELECT_BLOCK]: selectBlock,

  [WorkflowTypes.CREATE_BLOCK]: createBlock,

  [WorkflowTypes.MOVE_BLOCK]: moveBlock,
  [WorkflowTypes.ADD_CONNECTION]: addConnection,
  [WorkflowTypes.REMOVE_CONNECTION]: removeConnection,

  [WorkflowTypes.UPDATE_LOCAL_WORKFLOW]: updateLocalWorkflow,
  [WorkflowTypes.DELETE_LOCAL_WORKFLOW_BLOCK]: deleteLocalWorkflowBlock,
  [WorkflowTypes.DUPLICATES_BLOCK]: duplicatesBlock,

  [WorkflowTypes.UPDATE_WORKFLOW_REQUEST]: updateWorkflowRequest,
  [WorkflowTypes.UPDATE_WORKFLOW_SUCCESS]: updateWorkflowSuccess,
  [WorkflowTypes.UPDATE_WORKFLOW_FAILURE]: updateWorkflowFailure,
})
