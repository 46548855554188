import { Langs } from './api/interfaces';

export const getLang = (langs: Langs, lang: string | number) => {
  if (langs.hasOwnProperty(lang)) {
    return langs[lang];
  }
  return {
    label: '',
    uri: '',
  }
}

export const preventDefault = (event: any) => {
  if (event.hasOwnProperty('stopPropagation')) {
    event.stopPropagation();
  }
  if (event.hasOwnProperty('preventDefault')) {
    event.preventDefault();
  }
};
