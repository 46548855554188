import React from 'react';
import { preventDefault } from '../../../core/utils';

const WFEditorContainer = ({children, onScaleChange, scale, left, top, containerRef}: any) => {
  return (
    <div
      ref={containerRef}
      className="editor-container"
      onWheel={(e) => {
        onScaleChange(scale)
      }}
      onContextMenu={preventDefault}
    >
      <div className="editor-canvas"
        style={{
          // TODO: provare ad inserire il centro dello schermo
          // transformOrigin: (()=>{
          //   if (window.event && window.event instanceof MouseEvent) {
          //     const {x, y} = window.event
          //     return `${x}px ${y}px`;
          //   }
          //   return '0px 0px'
          // })(),
          height: '100%',
          pointerEvents: 'none',
          left,
          top,
          zoom: scale,
          transform: 'translate(50%, 50%)',
        }}
      >
        {children}
      </div>
      {/* <div style={(
        ()=>{
          const base = {
            backgroundColor: 'red',
            width: '2px',
            height: '2px',
            position: 'fixed',
          }
          if (window.event && window.event instanceof MouseEvent) {
            const {x, y} = window.event
            return {
              ...base,
              left: x + 'px',
              top: y + 'px',
            };
          }
          return base;
        }
      )() as any} /> */}
    </div>
  );
};

export default WFEditorContainer;
