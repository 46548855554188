import { createActions } from 'reduxsauce'

const { Types, Creators } = createActions({
  sidebarSetContent: ['data'],
  setListUrl: ['data'],
  setPropUrl: ['data'],
})

export const GeneralTypes = Types
export default Creators
