import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
// import Api from '../../core/api/api';
// import { Workflow } from '../../core/api/interfaces';
import LoaderComponent from '../components/atoms/LoaderComponent';
// import WFEditor from '../components/organisms/WFEditor';
import WFEditor from '../components/organisms/WFEditor';
import { useDispatch, useSelector } from 'react-redux';
import WorkflowActions from '../../core/stores/workflows/actions';
import GeneralActions from '../../core/stores/general/actions';
import Api from '../../core/api/api';
import { Workflow } from '../../core/api/interfaces';
import i18n from '../../core/i18n/i18n';

const Error = (error: any): JSX.Element => (
  <span className="text-danger">{typeof error === 'string' ? error : JSON.stringify(error)}</span>
);

const WorkflowEdit: React.FC = () => {
  const id = useParams<any>().workflow_id;

  // const { list, prop } = useSelector((state: any) => state.general);

  const {data, loading, selectedWorkflowId} = useSelector((state: any) => state.workflows);
  const [isPageLoading, setPageLoading] = useState(true)
  const workflow = useMemo(() => data[selectedWorkflowId] as Workflow, [data, selectedWorkflowId]);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(GeneralActions.sidebarSetContent({
      type: 'menu'
    }))
    if (!isPageLoading) {
      dispatch(GeneralActions.sidebarSetContent({
        type: 'editor'
      }))
    }
  }, [id, dispatch, isPageLoading]);

  useEffect(() => {
    if (!data.hasOwnProperty(id) || !data[id].edited) {
      setPageLoading(false);
      dispatch(WorkflowActions.loadWorkflowRequest(id));
    }
    // Api.getWorkflowDetails(id).then(serverWF => {
    //   if (serverWF.timestamp > data[id].timestamp) {
    //     console.log('serverWF is newer than data[dataIndex]');
    //   }
    // });
  }, []);

  return (
    <div className="h-100">
      {isPageLoading ? (
        <>
          <p>C'era un editing in corso, vuoi continuare?</p>
          <button className="btn btn-primary" onClick={() => {
            setPageLoading(false);
          }}>Si</button>
          <button className="btn btn-secondary" onClick={() => {
            dispatch(WorkflowActions.loadWorkflowRequest(id));
            setPageLoading(false);
          }}>No</button>
        </>
      ) : (
        loading
          ? <LoaderComponent />
          : workflow
            ? (
              <>
                <WFEditor
                  isEditable={true}
                  workflow={workflow}
                  onBlockSelect={(block_id) => dispatch(WorkflowActions.selectBlock(block_id))}
                  onBlockDragged={(values) => dispatch(WorkflowActions.moveBlock(values))}
                  onAddConnection={(values) => dispatch(WorkflowActions.addConnection(values))}
                  onDelConnection={(values) => dispatch(WorkflowActions.removeConnection(values))}
                />
              </>
            ) : <Error error={workflow} />
      )}
    </div>
  );
};

export default WorkflowEdit;
