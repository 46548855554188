import Axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { Response } from './interfaces';

// const BASE_PATH = 'http://dev.wofobo.com/flow.php';

let endpoint: string = '';
let auk: string = '';

const setEndpoint = (e: string) => {
  endpoint = e;
}

const setAuk = (a: string) => {
  auk = a;
}

const getEndpoint = () => {
  if (endpoint === '') {
    console.log('~error')
    // throw new Error();
  }
  return endpoint;
}

const getAuk = () => {
  if (auk === '') {
    console.log('~error')
    // throw new Error();
  }
  return auk;
}

const getWorkflows = () => {
  return Axios.request({
    url: getEndpoint() + '?C=L',
    method: 'GET',
  }).then(res => res.data)
};

const getWorkflowDetails = (id: string) => {
  return Axios.request({
    url: getEndpoint() + '?C=E&CWID=' + id,
    method: 'GET',
  }).then(res => res.data.workflow[0])
};

const updateWorkflowBlock = (data: any) => {
  console.log(data);
  return Axios.request({
    url: getEndpoint() + '?C=U&CWID=' + data.wid,
    method: 'POST',
    data,
  }).then(res => res.data)
};

const getUUID = (quantity: number = 1) => {
  return Axios.request({
    url: getEndpoint() + '?C=UUID&QTY=' + quantity,
    method: 'GET',
  }).then(res => res.data.uuid)
}

const createWorkflow = (name: string, description: string, suppLangs: string[]) => {
  return Axios.request({
    url: getEndpoint() + '?C=C&N=' + name,
    method: 'POST',
    data: {
      name,
      description,
      supp_langs: suppLangs.join(','),
    }
  }).then(res => res.data)
}

const deleteWorkflow = (wid: string) => {
  return Axios.request({
    url: getEndpoint() + '?C=D&CWID=' + wid,
    method: 'GET',
  }).then(res => res.data)
}

const getSubWorkflowList = () => {
  return Axios.request({
    url: getEndpoint() + '?auk=' + getAuk() + '&C=US',
    method: 'GET',
  }).then(res => res.data)
}

const Api = {
  setEndpoint,
  setAuk,

  getWorkflows,
  getWorkflowDetails,
  updateWorkflowBlock,
  getUUID,
  createWorkflow,
  deleteWorkflow,
  getSubWorkflowList,
};

export default Api;
