/**
 * The initial values for the redux state.
 */
export const INITIAL_STATE = {
  data: false,
  loading: false,
  errors: false,
  selectedBlockId: false,
  selectedWorkflowId: false,
}
