import { combineReducers } from 'redux'
import configureStore from './createStore'
import rootSaga from '../sagas'
import { reducer as WorkflowsReducer } from './workflows/reducers'
import { reducer as GeneralReducer } from './general/reducers'

const stores = () => {
  const rootReducer = combineReducers({
    /**
     * Register your reducers here.
     * @see https://redux.js.org/api-reference/combinereducers
     */
    // auth: AuthReducer,
    general: GeneralReducer,
    workflows: WorkflowsReducer,
  })

  return configureStore(rootReducer, rootSaga)
}

export default stores;
