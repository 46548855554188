import { takeLatest, all } from 'redux-saga/effects'

// import { StartupTypes } from '../stores/startup/actions'
// import { startup } from './startupSaga'
import { WorkflowTypes } from '../stores/workflows/actions';

import { loadWorkflow, updateWorkflowBlock } from './workflowSaga';

export default function* root() {
  yield all([
    /**
     * @see https://redux-saga.js.org/docs/basics/UsingSagaHelpers.html
     */
    // Run the startup saga when the application starts
    // takeLatest(StartupTypes.STARTUP, startup),
    takeLatest((WorkflowTypes as any).LOAD_WORKFLOW_REQUEST, loadWorkflow),
    takeLatest((WorkflowTypes as any).UPDATE_WORKFLOW_REQUEST, updateWorkflowBlock),
  ])
}
